<template>
    <div>
       欢迎您登录汇工富管理平台
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>


</style>