<template>
  <div class="login_container">
    <div class="login_box">
      <div class="avatar_box">
        <img src="../assets/logosmall.png" alt="" />
      </div>
      <div class="login_title">汇工富管理平台</div>
      <el-form ref="loginFormRef" :rules="loginFormRules" :model="loginForm" label-width="0px" class="login_form">
        <!-- 用户名 -->
        <el-form-item prop="LoginName">
          <el-input v-model="loginForm.LoginName" prefix-icon="iconfont iconuser"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="UserPassword">
          <el-input type="password" v-model="loginForm.UserPassword" prefix-icon="iconfont iconlock"></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="support_box" v-show="hidshow">技术支持：汇工富(深圳)科技有限公司</div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        LoginName: '',
        UserPassword: '',
      },
      docmHeight: document.documentElement.clientHeight || document.body.clientHeight,
      showHeight: document.documentElement.clientHeight || document.body.clientHeight,
      hidshow: true, //是否显示底部
      isMobile: false, //是否为移动端
      loginFormRules: {
        LoginName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        UserPassword: [
          { required: true, message: '请输入面密码', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    // 重置表单按钮
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post('user/UserLogin', this.loginForm).catch(err => {
          return this.$message.error('不能连接到服务器，请联系技术人员');
        });

        if (res.Code !== '200') {
          return this.$message.error(res.Message);

        }
        this.$message.success(res.Message);

        window.sessionStorage.setItem('userName', res.Obj.UserName);
        window.sessionStorage.setItem('roleName', res.Obj.RoleName);
        window.sessionStorage.setItem('roleId', res.Obj.RoleId);
        window.sessionStorage.setItem('phone', res.Obj.PhoneNumber);
        window.sessionStorage.setItem('id', res.Obj.id);
       
        window.sessionStorage.setItem('rootUrl', this.$http.defaults.rootURL);
        window.sessionStorage.setItem('baseUrl', this.$http.defaults.baseURL);

        this.$router.push('/home').catch(err => {
          console.log(err);
        });
      });
    }
  },
  created() {
    var res = this.$common._isMobile();
    if (res == null || res == '') {
      this.isMobile = false;
      window.sessionStorage.setItem('isMobile', false);
    }
    else {
      this.isMobile = true;
      window.sessionStorage.setItem('isMobile', true);
    }
    this.docmHeight = document.documentElement.clientHeight;
    //console.log('h:' + this.isMobile)
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight
        if (this.docmHeight > this.showHeight) {
          this.hidshow = false
        } else {
          this.hidshow = true
        }
      })()
    }
  },
  watch: {
    //监听显示高度
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        //隐藏
        this.hidshow = false
      } else {
        //显示
        this.hidshow = true
      }
      console.log(this.showHeight + ',' + this.docmHeight)
      console.log(this.hidshow)
    }
  },
};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}
.support_box {
  position: absolute;
  bottom: 5%;
  left:0;
  width: 100%;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
}
.login_box {
  width: 350px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* x轴，y轴各偏移50% */
  .avatar_box {
    height: 80px;
    width: 80px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}
.btns {
  display: flex;
  justify-content: flex-end;
}
.login_form {
  position:absolute;
  top:40%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.login_title {
  text-align: center;
  font-size: 20px;
  width:100%;
  font-weight: 600;
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%);
  color: #2b4b6b;
}
</style>
